@import "~bootstrap/scss/bootstrap";
@import "grayscale";
@import "bdl";

.table {
  th {
    background-color: transparent;
  }

  td {
    background-color: transparent;
  }
}

.myth-logo {
  max-height: 200px;
  width: auto;
}